import api from './api';

export const addProduct = async (productData) => {
    return api.post('/product/add', productData);
};

export const getCategories = async () => {
    return api.get('/categories');
}; 

export const getProducts = async () => {
    return api.get('/products');
}; 

export const deleteProduct = async (productId) => {
    return api.delete(`/product/delete/${productId}`);
};