import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Table, Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { getProducts, deleteProduct } from '../../Services/apiInteractor';

const ProductList = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        try {
            setLoading(true);
            const response = await getProducts();
            setProducts(Array.isArray(response.data.data.products) ? response.data.data.products : []);
        } catch (error) {
            console.error('Ürünler yüklenirken hata oluştu:', error);
            setProducts([]);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteClick = (product) => {
        setSelectedProduct(product);
        setDeleteModal(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            await deleteProduct(selectedProduct._id);
            await fetchProducts(); // Refresh the list
            setDeleteModal(false);
            setSelectedProduct(null);
        } catch (error) {
            console.error('Ürün silinirken hata oluştu:', error);
            // You might want to show an error message to the user here
        }
    };

    return (
        <>
            <Card>
                <CardHeader>
                    <h5>Ürün Listesi</h5>
                </CardHeader>
                <CardBody>
                    <Table hover responsive>
                        <thead>
                            <tr>
                                <th>İsim</th>
                                <th>Görsel</th>
                                <th>İşlemler</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan="3" className="text-center">Yükleniyor...</td>
                                </tr>
                            ) : products.length === 0 ? (
                                <tr>
                                    <td colSpan="3" className="text-center">Ürün bulunamadı</td>
                                </tr>
                            ) : (
                                products.map((product) => (
                                    <tr key={product._id}>
                                        <td>{product.name || 'Belirtilmemiş'}</td>
                                        <td>
                                            {product.imageUrl ? (
                                                <img 
                                                    src={product.imageUrl} 
                                                    alt={product.name} 
                                                    style={{ height: '50px', width: '50px', objectFit: 'cover' }}
                                                />
                                            ) : (
                                                'Görsel yok'
                                            )}
                                        </td>
                                        <td>
                                            <button className="btn btn-primary btn-sm">Düzenle</button>
                                            <button 
                                                className="btn btn-danger btn-sm ms-2"
                                                onClick={() => handleDeleteClick(product)}
                                            >
                                                Sil
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>

            {/* Confirmation Modal */}
            <Modal isOpen={deleteModal} toggle={() => setDeleteModal(false)}>
                <ModalBody>
                    Silmek istediğinize emin misiniz?
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setDeleteModal(false)}>
                        İptal
                    </Button>
                    <Button color="danger" onClick={handleDeleteConfirm}>
                        Evet
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default ProductList; 