import React from 'react';
import ProductListComponent from '../../Components/Products/ProductList';
import { Container, Row, Col } from 'reactstrap';

const ProductList = () => {
    return (
        <Container fluid={true}>
            <Row>
                <Col sm="12">
                    <ProductListComponent />
                </Col>
            </Row>
        </Container>
    );
};

export default ProductList; 