import React, { useState, useEffect } from 'react';
import { Card, CardBody, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { addProduct, getCategories } from '../../../Services/apiInteractor';

const AddProduct = () => {
    const [product, setProduct] = useState({
        name: '',
        description: '',
        price: '',
        image: null,
        categoryId: ''
    });

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await getCategories();
            
                setCategories(Array.isArray(response.data.data.categories) ? response.data.data.categories  : []);
                console.log(response.data.data)
            } catch (error) {
                console.error('Error fetching categories:', error);
                alert('Kategoriler yüklenirken bir hata oluştu');
                setCategories([]);
            }
        };

        fetchCategories();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProduct(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleImageChange = (e) => {
        setProduct(prev => ({
            ...prev,
            image: e.target.files[0]
        }));
    };

    const convertImageToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result.split(',')[1]);
            reader.onerror = error => reject(error);
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let imageBase64 = null;
            if (product.image) {
                imageBase64 = await convertImageToBase64(product.image);
            }

            const requestBody = {
                name: product.name,
                description: product.description,
                price: product.price,
                imageBase64,
                categoryId: product.categoryId
            };

            await addProduct(requestBody);
            alert('Ürün başarıyla eklendi');
            // Reset form
            setProduct({
                name: '',
                description: '',
                price: '',
                image: null,
                categoryId: ''
            });
        } catch (error) {
            console.error('Error adding product:', error);
            alert('Ürün eklenirken bir hata oluştu');
        }
    };

    return (
        <Card>
            <CardBody>
                <h4 className="card-title mb-4">Yeni Ürün Ekle</h4>
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label for="name">Ürün Adı</Label>
                        <Input
                            type="text"
                            name="name"
                            id="name"
                            value={product.name}
                            onChange={handleInputChange}
                            placeholder="Ürün adı giriniz"
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="description">Açıklama</Label>
                        <Input
                            type="textarea"
                            name="description"
                            id="description"
                            value={product.description}
                            onChange={handleInputChange}
                            placeholder="Ürün açıklaması giriniz"
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="price">Fiyat</Label>
                        <Input
                            type="number"
                            name="price"
                            id="price"
                            value={product.price}
                            onChange={handleInputChange}
                            placeholder="Fiyat giriniz"
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="categoryId">Kategori</Label>
                        <Input
                            type="select"
                            name="categoryId"
                            id="categoryId"
                            value={product.categoryId}
                            onChange={handleInputChange}
                        >
                            <option value="">Kategori Seçiniz</option>
                            {Array.isArray(categories) && categories.map(category => (
                                <option key={category._id} value={category._id}>
                                    {category.name}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>

                    <FormGroup>
                        <Label for="image">Ürün Görseli</Label>
                        <Input
                            type="file"
                            name="image"
                            id="image"
                            onChange={handleImageChange}
                            accept="image/*"
                        />
                    </FormGroup>

                    <Button color="primary" type="submit">
                        Ürün Ekle
                    </Button>
                </Form>
            </CardBody>
        </Card>
    );
};

export default AddProduct;
