import Default from '../Pages/DashBoard/Default/Default';
import Crypto from '../Pages/DashBoard/Crypto';
import CampaignList from '../Pages/Campaign/CampaignList';
import CampaignCreate from '../Pages/Campaign/CampaignCreate';
import CampaignEdit from '../Pages/Campaign/CampaignEdit';
import Charts from '../Pages/Widgets/Charts';
import General from '../Pages/Widgets/General';
import UsersProfile from '../Pages/Users/UsersProfile';
import UsersEdit from '../Pages/Users/UsersEdit';
import UsersCardss from '../Pages/Users/UsersCards';
import Typography from '../Pages/Ui-kit/Typography';
import Avatars from '../Pages/Ui-kit/Avtars';
import HelperClasses from '../Pages/Ui-kit/HelperClass';
import Grid from '../Pages/Ui-kit/Grid';
import TagAndPills from '../Pages/Ui-kit/TagAndPills';
import Progressbars from '../Pages/Ui-kit/Progress';
import Modals from '../Pages/Ui-kit/Modals';
import Alerts from '../Pages/Ui-kit/Alert';
import PopOvers from '../Pages/Ui-kit/Popover';
import Tooltips from '../Pages/Ui-kit/Tooltip';
import Spinners from '../Pages/Ui-kit/Spinners';
import Dropdowns from '../Pages/Ui-kit/Dropdown';
import TabBootstrap from '../Pages/Ui-kit/Tabs/BootstrapTab';
import TabLine from '../Pages/Ui-kit/Tabs/LineTab';
import Accordian from '../Pages/Ui-kit/Accordian';
import Shadow from '../Pages/Ui-kit/Shadows';
import List from '../Pages/Ui-kit/Lists';
import Calender from '../Pages/Calender/Index';
import Draggable from '../Pages/Calender/Draggable Calender';
import FormValidation from '../Pages/Forms/Form Control/FormValidation';
import BaseInput from '../Pages/Forms/Form Control/BaseInput';
import InputGroups from '../Pages/Forms/Form Control/InputGroup';
import MegaOptions from '../Pages/Forms/Form Control/MegaOptions';
import RadioCheckbox from '../Pages/Forms/Form Control/RadioCheckbox';
import Bookmarks from '../Pages/BookMarks';
import FormDatapicker from '../Pages/Forms/Form Widgets/FormDatepicker';
import FormTypeahead from '../Pages/Forms/Form Widgets/FormTypeahead';
import FormDefault from '../Pages/Forms/Form Layout/FormDefault';
import FormWizard from '../Pages/Forms/Form Layout/FormWizard';
import FormDateTime from '../Pages/Forms/Form Widgets/FormDateTime';
import FormSelect2 from '../Pages/Forms/Form Widgets/FormSelect2';
import FormSwitch from '../Pages/Forms/Form Widgets/FormSwitch';
import FormTouchspin from '../Pages/Forms/Form Widgets/FormTouchSpin';
import FormDateRangepicker from '../Pages/Forms/Form Widgets/FormDateRangepicker';
import FromClipboard from '../Pages/Forms/Form Widgets/FormClipboard';
import BlogDetail from '../Pages/Blog/BlogDetail';
import BlogPost from '../Pages/Blog/BlogPost';
import BlogSingle from '../Pages/Blog/BlogSingle';
import CKEditor from '../Pages/Editor/CkEditor';
import MDEEditor from '../Pages/Editor/MDEEditor';
import ACEEditor from '../Pages/Editor/ACECodeEditor';
import SamplePage from '../Pages/Sample Page';
import LeafletMap from '../Pages/Maps/LeafletMap';
import MapJs from '../Pages/Maps/MapJs';
import SupportTickit from '../Pages/Support Tickit';
import DataTable from '../Pages/Tables/DataTable';
import BasicTable from '../Pages/Tables/Reactstrap Table/BasicTable';
import SizingTable from '../Pages/Tables/Reactstrap Table/Sizingtable';
import StylingTable from '../Pages/Tables/Reactstrap Table/StylingTable';
import BorderTable from '../Pages/Tables/Reactstrap Table/BorderTable';
import DefaultStyle from '../Pages/Buttons/Defaultstyle';
import FlagIcon from '../Pages/Icons/FlagIcons';
import FontAwesome from '../Pages/Icons/FontAwesom';
import IcoIcons from '../Pages/Icons/IcoIcons';
import ThemifyIcon from '../Pages/Icons/ThemifyIcon';
import FeatherIcon from '../Pages/Icons/FeatherIcon';
import WhetherIcon from '../Pages/Icons/WhetherIcon';
import ApexChart from '../Pages/Charts/ApexChart';
import ChartJs from '../Pages/Charts/ChartJs';
import GoogleChart from '../Pages/Charts/GoogleChart';
import BasicCard from '../Pages/Bonus UI/BasicCard';
import CreativeCard from '../Pages/Bonus UI/CreativeCard';
import TabCard from '../Pages/Bonus UI/TabCard';
import Carousel from '../Pages/Bonus UI/Carousels';
import BreadcrumbsClass from '../Pages/Bonus UI/Breadcrumb';
import ImageCroppers from '../Pages/Bonus UI/ImageCropper';
import Scrollables from '../Pages/Bonus UI/Scrollable';
import BootstrapNotify from '../Pages/Bonus UI/BootstrapNotify';
import RatingClass from '../Pages/Bonus UI/Rating';
import DropzoneClass from '../Pages/Bonus UI/Dropzone';
import Tours from '../Pages/Bonus UI/Tours';
import Ribbons from '../Pages/Bonus UI/Ribbon';
import RangeSlider from '../Pages/Bonus UI/RangeSilder';
import Pagination from '../Pages/Bonus UI/Pagination';
import ImageUpload from '../Pages/Bonus UI/ImageUpload';
import Sticky from '../Pages/Bonus UI/Sticky';
import TimeLines from '../Pages/Bonus UI/TimeLine';
import SweetAlerts from '../Pages/Bonus UI/SweetAleart';
import TreeViews from '../Pages/Bonus UI/TreeView';
import DragAndDrop from '../Pages/Bonus UI/DragAndDrop';
import Steps from '../Pages/Bonus UI/Steps';
import CreateCategoryPage from '../Pages/Categories/CategoryCreate'
import CategoryListPage from '../Pages/Categories/CategoryList'
import CategoryEdit from '../Pages/Categories/CategoryEdit'
import AddProduct from '../Pages/Products/CreateProduct';
import ProductList from '../Pages/Products/ProductList';


export const routes = [
    { path: `${process.env.PUBLIC_URL}/dashboard/default/`, Component: <Default /> },

    { path: `${process.env.PUBLIC_URL}/widgets/general/`, Component: <General /> },
    { path: `${process.env.PUBLIC_URL}/widgets/chart/`, Component: <Charts /> },
    { path: `${process.env.PUBLIC_URL}/campaigns/general/`, Component: <CampaignList /> },
    { path: `${process.env.PUBLIC_URL}/campaigns/create/`, Component: <CampaignCreate /> },
    { path: `${process.env.PUBLIC_URL}/campaigns/edit/`, Component: <CampaignEdit /> },
   
    { path: `${process.env.PUBLIC_URL}/menu/create-category/`, Component: <CreateCategoryPage />},
    { path: `${process.env.PUBLIC_URL}/menu/categories/`, Component: <CategoryListPage />},
    { path: `${process.env.PUBLIC_URL}/menu/edit-category/`, Component: <CategoryEdit />},
    { path: `${process.env.PUBLIC_URL}/menu/create-product/`, Component: <AddProduct /> },
    { path: `${process.env.PUBLIC_URL}/menu/products/`, Component: <ProductList /> },
    // { path: `${process.env.PUBLIC_URL}/menu/create-category`, title: 'Kategori Oluştur', type: 'link' },
    { path: `${process.env.PUBLIC_URL}/menu/products`, title: 'Ürünler', type: 'link' },
    // { path: `${process.env.PUBLIC_URL}/menu/create-product`, title: 'Ürün Oluştur', type: 'link' },



    { path: `${process.env.PUBLIC_URL}/users/userprofile`, Component: <UsersProfile /> },
    { path: `${process.env.PUBLIC_URL}/users/useredit`, Component: <UsersEdit /> },
    { path: `${process.env.PUBLIC_URL}/users/usercard`, Component: <UsersCardss /> },

    { path: `${process.env.PUBLIC_URL}/bookmarks`, Component: <Bookmarks /> },

    { path: `${process.env.PUBLIC_URL}/calandar/basic-calandar`, Component: <Calender /> },
    { path: `${process.env.PUBLIC_URL}/calandar/draggable-calandar`, Component: <Draggable /> },


    { path: `${process.env.PUBLIC_URL}/forms-control/formValidation`, Component: <FormValidation /> },
    { path: `${process.env.PUBLIC_URL}/forms-control/baseInput`, Component: <BaseInput /> },
    { path: `${process.env.PUBLIC_URL}/forms-control/inputGroup`, Component: <InputGroups /> },
    { path: `${process.env.PUBLIC_URL}/forms-control/megaOptions`, Component: <MegaOptions /> },
    { path: `${process.env.PUBLIC_URL}/forms-control/radioCheckbox`, Component: <RadioCheckbox /> },

    { path: `${process.env.PUBLIC_URL}/form-widget/datepicker`, Component: <FormDatapicker /> },
    { path: `${process.env.PUBLIC_URL}/form-widget/typeahead`, Component: <FormTypeahead /> },
    { path: `${process.env.PUBLIC_URL}/form-widget/datetimepicker`, Component: <FormDateTime /> },
    { path: `${process.env.PUBLIC_URL}/form-widget/select2`, Component: <FormSelect2 /> },
    { path: `${process.env.PUBLIC_URL}/form-widget/switch`, Component: <FormSwitch /> },
    { path: `${process.env.PUBLIC_URL}/form-widget/touchspin`, Component: <FormTouchspin /> },
    { path: `${process.env.PUBLIC_URL}/form-widget/rangepicker`, Component: <FormDateRangepicker /> },
    { path: `${process.env.PUBLIC_URL}/form-widget/clipboard`, Component: <FromClipboard /> },

    { path: `${process.env.PUBLIC_URL}/form-layout/formDefault`, Component: <FormDefault /> },
    { path: `${process.env.PUBLIC_URL}/form-layout/formWizard`, Component: <FormWizard /> },

    { path: `${process.env.PUBLIC_URL}/blog/blogdetail`, Component: <BlogDetail /> },
    { path: `${process.env.PUBLIC_URL}/blog/blogsingle`, Component: <BlogSingle /> },
    { path: `${process.env.PUBLIC_URL}/blog/blogpost`, Component: <BlogPost /> },

    { path: `${process.env.PUBLIC_URL}/ui-kits/typography/`, Component: <Typography /> },
    { path: `${process.env.PUBLIC_URL}/ui-kits/avatar/`, Component: <Avatars /> },
    { path: `${process.env.PUBLIC_URL}/ui-kits/helperclass/`, Component: <HelperClasses /> },
    { path: `${process.env.PUBLIC_URL}/ui-kits/grid/`, Component: <Grid /> },
    { path: `${process.env.PUBLIC_URL}/ui-kits/tagsandpills/`, Component: <TagAndPills /> },
    { path: `${process.env.PUBLIC_URL}/ui-kits/progress-bar/`, Component: <Progressbars /> },
    { path: `${process.env.PUBLIC_URL}/ui-kits/modal/`, Component: <Modals /> },
    { path: `${process.env.PUBLIC_URL}/ui-kits/alert/`, Component: <Alerts /> },
    { path: `${process.env.PUBLIC_URL}/ui-kits/popover/`, Component: <PopOvers /> },
    { path: `${process.env.PUBLIC_URL}/ui-kits/tooltips/`, Component: <Tooltips /> },
    { path: `${process.env.PUBLIC_URL}/ui-kits/spinner/`, Component: <Spinners /> },
    { path: `${process.env.PUBLIC_URL}/ui-kits/dropdown/`, Component: <Dropdowns /> },
    { path: `${process.env.PUBLIC_URL}/ui-kits/tab-bootstrap/`, Component: <TabBootstrap /> },
    { path: `${process.env.PUBLIC_URL}/ui-kits/tab-line/`, Component: <TabLine /> },
    { path: `${process.env.PUBLIC_URL}/ui-kits/accordion/`, Component: <Accordian /> },
    { path: `${process.env.PUBLIC_URL}/ui-kits/shadow/`, Component: <Shadow /> },
    { path: `${process.env.PUBLIC_URL}/ui-kits/list/`, Component: <List /> },



    {
        path: `${process.env.PUBLIC_URL}/editor/ckEditor`, Component: <CKEditor />
    },
    {
        path: `${process.env.PUBLIC_URL}/editor/mdeEditor`, Component: <MDEEditor />
    },
    {
        path: `${process.env.PUBLIC_URL}/editor/ace-code-editor`, Component: <ACEEditor />
    },


    {
        path: `${process.env.PUBLIC_URL}/sample-page`, Component: <SamplePage />
    },

    {
        path: `${process.env.PUBLIC_URL}/mapsjs`, Component: <MapJs />
    },
    {
        path: `${process.env.PUBLIC_URL}/leafletmap`, Component: <LeafletMap />
    },

    {
        path: `${process.env.PUBLIC_URL}/support-ticket`, Component: <SupportTickit />
    },


    {
        path: `${process.env.PUBLIC_URL}/tables/basictable`, Component: <BasicTable />
    },
    {
        path: `${process.env.PUBLIC_URL}/tables/sizingtable`, Component: <SizingTable />
    },
    {
        path: `${process.env.PUBLIC_URL}/tables/stylingtable`, Component: <StylingTable />
    },
    {
        path: `${process.env.PUBLIC_URL}/tables/bordertable`, Component: <BorderTable />
    },
    {
        path: `${process.env.PUBLIC_URL}/tables/datatable`, Component: <DataTable />
    },

    {
        path: `${process.env.PUBLIC_URL}/button`, Component: <DefaultStyle />
    },

    {
        path: `${process.env.PUBLIC_URL}/icons/flagicon`, Component: <FlagIcon />
    },
    {
        path: `${process.env.PUBLIC_URL}/icons/fontawesome`, Component: <FontAwesome />
    },
    {
        path: `${process.env.PUBLIC_URL}/icons/ico`, Component: <IcoIcons />
    },
    {
        path: `${process.env.PUBLIC_URL}/icons/thimify`, Component: <ThemifyIcon />
    },
    {
        path: `${process.env.PUBLIC_URL}/icons/feather`, Component: <FeatherIcon />
    },
    {
        path: `${process.env.PUBLIC_URL}/icons/wheater`, Component: <WhetherIcon />
    },

    {
        path: `${process.env.PUBLIC_URL}/charts/apex`, Component: <ApexChart />
    },
    
    {
        path: `${process.env.PUBLIC_URL}/charts/chartjs`, Component: <ChartJs />
    },
    {
        path: `${process.env.PUBLIC_URL}/charts/google`, Component: <GoogleChart />
    },

    {
        path: `${process.env.PUBLIC_URL}/bonus-ui/card/basicCards`, Component: <BasicCard />
    },
    {
        path: `${process.env.PUBLIC_URL}/bonus-ui/card/creativeCards`, Component: <CreativeCard />
    },
    {
        path: `${process.env.PUBLIC_URL}/bonus-ui/card/tabCard`, Component: <TabCard />
    },
    {
        path: `${process.env.PUBLIC_URL}/bonus-ui/carousel`, Component: <Carousel />
    },
    {
        path: `${process.env.PUBLIC_URL}/bonus-ui/breadcrumb`, Component: <BreadcrumbsClass />
    },
    {
        path: `${process.env.PUBLIC_URL}/bonus-ui/imageCropper`, Component: <ImageCroppers />
    },
    {
        path: `${process.env.PUBLIC_URL}/bonus-ui/scrollable`, Component: <Scrollables />
    },
    {
        path: `${process.env.PUBLIC_URL}/bonus-ui/bootstrap-notify`, Component: <BootstrapNotify />
    },
    {
        path: `${process.env.PUBLIC_URL}/bonus-ui/rating`, Component: <RatingClass />
    },
    {
        path: `${process.env.PUBLIC_URL}/bonus-ui/dropzone`, Component: <DropzoneClass />
    },
    {
        path: `${process.env.PUBLIC_URL}/bonus-ui/tourComponent`, Component: <Tours />
    },
    {
        path: `${process.env.PUBLIC_URL}/bonus-ui/ribbons`, Component: <Ribbons />
    },
    {
        path: `${process.env.PUBLIC_URL}/bonus-ui/rangeSlider`, Component: <RangeSlider />
    },
    {
        path: `${process.env.PUBLIC_URL}/bonus-ui/pagination`, Component: <Pagination />
    },
    {
        path: `${process.env.PUBLIC_URL}/bonus-ui/image-upload`, Component: <ImageUpload />
    },
    {
        path: `${process.env.PUBLIC_URL}/bonus-ui/stickyNotes`, Component: <Sticky />
    },
    {
        path: `${process.env.PUBLIC_URL}/bonus-ui/timelines/timeline1`, Component: <TimeLines />
    },
    {
        path: `${process.env.PUBLIC_URL}/bonus-ui/sweetAlert`, Component: <SweetAlerts />
    },
    {
        path: `${process.env.PUBLIC_URL}/bonus-ui/treeview`, Component: <TreeViews />
    },
    {
        path: `${process.env.PUBLIC_URL}/bonus-ui/dragNDropComp`, Component: <DragAndDrop />
    },
    {
        path: `${process.env.PUBLIC_URL}/bonus-ui/step`, Component: <Steps />
    },

];