import axios from 'axios';

const BASE_URL = 'https://sea-lion-app-erqaa.ondigitalocean.app/admin/';

const api = axios.create({
    baseURL: BASE_URL,
});

// Request interceptor for adding auth token
api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
});

export default api;

export const addProduct = async (productData) => {
    return api.post('/product/add', productData);
};

export const getCategories = async () => {
    return api.get('/category/list');
}; 

export const getProducts = async () => {
    return api.get('/products');
}; 

export const deleteProduct = async (productId) => {
    return api.delete(`/product/delete/${productId}`);
};  